import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "ui vertical masthead center aligned",
  id: "header"
}
const _hoisted_2 = { id: "header-content" }
const _hoisted_3 = { class: "ui inverted header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("h1", _hoisted_3, _toDisplayString(_ctx.header), 1),
      _createVNode("p", null, _toDisplayString(_ctx.subHeader), 1)
    ])
  ]))
}